import { createStore } from "vuex";

export default createStore({
  state: {
    birds: []
  },
  getters: {},
  mutations: {

  },
  modules: {},
});
